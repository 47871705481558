@import "../SCSS/config";

.thankyou-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 30vh;
  h1 {
    font-weight: 800;
    color: $HeadingColor;
  }

  p {
    color: $ParagraphColor;
  }

  a {
    color: $white;
    background-color: $PrimaryColor;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    margin: 15px;
    transition: 0.3s ease-in-out;
  }

  a:hover {
    border: 1px solid $PrimaryColor;
    background-color: $white;
    color: $PrimaryColor;
  }
}
