@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap");

$Roboto: "Roboto", sans-serif;
$Poppins: "Poppins", sans-serif;

//------------------------------ Colors ---------------------------
$PrimaryColor: #4d62a9;
$HeadingColor: #313131;
$ParagraphColor: #9b9b9b;
$GhostColor: #f5f5f5;
$AccentColor: #99aff8;
$VeryLightPrimaryColor: #ffecd5;
$black: rgb(0, 0, 0);
$SemiBlack: rgb(95, 95, 95);
$Grey: #b3b3b3;
$white: #ffff;
$offwhite: rgb(250, 250, 250);

//------------------------------ Custom Properties -------------------

:root {
  --fs-mainheading: clamp(2rem, 10vw + 1rem, 2.5rem);
  --fs-heading: 1.5rem;
  --fs-paragraph: 1rem;
}

::selection {
  background-color: $PrimaryColor;
  color: $white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p,
ul {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $Poppins;
  color: $HeadingColor;
}

p {
  font-family: $Roboto;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  font-family: $Roboto;
}

.seprator-left {
  margin-left: 0;
  margin-top: 6vh;
  margin-bottom: 6vh;
  width: 30%;
  height: 0.6vh;
  border-radius: 10px;
  background-color: $HeadingColor;
}

.seprator-right {
  margin-right: 0;
  margin-top: 6vh;
  margin-bottom: 6vh;
  width: 40%;
  background-color: $PrimaryColor;
  height: 0.6vh;
}

@media only screen and (max-width: 1244px) {
  :root {
    --fs-mainheading: clamp(2rem, 10vw + 1rem, 1.75rem);
    --fs-paragraph: 0.8125rem;
  }

  .pad {
    padding: 0vh 5vh;
  }

  .seprator-left {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 30%;
    height: 0.5vh;
    border-radius: 10px;
    background-color: $HeadingColor;
  }

  .seprator-right {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 40%;
    height: 0.5vh;
  }
}

@media only screen and (max-width: 760px) {
  :root {
    --fs-mainheading: clamp(1rem, 10vw + 1rem, 1.5rem);
    --fs-paragraph: 0.75rem;
    --fs-heading: 1rem;
  }

  .pad {
    padding: 0vh 5vh;
  }
}
