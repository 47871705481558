@import "../SCSS/config";

.gallery-items {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50vh;
  }
}

@media only screen and (max-width: 1000px) {
  .gallery-items {
    padding: 0vh 2vh;
    img {
      width: 15vh;
    }
  }
}
