@import "../SCSS/config";
.Navbar {
  padding: 5vh;
}

.logo-img {
  width: 40vh;
}

.links-container {
  width: 100%;
  justify-content: end;

  a {
    padding: 30px !important;
    font-size: 20px;
    color: $SemiBlack;
  }
}

@media only screen and (max-width: 980px) {
  .Navbar {
    padding: 4vh;
  }

  .logo-img {
    width: 25vh;
  }

  .navbar-brand {
    margin-right: 0px;
    padding-bottom: 0px;
  }

  .links-container {
    padding: 0.5rem 1.5rem;
    a {
      background-color: $GhostColor;
      padding: 0.5rem 1rem !important;
      border: 0.3px solid $Grey;
      margin: 5px;
      font-size: var(--fs-paragraph);
    }
  }
}
