@import "../SCSS/config";

.contact-us-container {
  text-align: center;

  h1 {
    font-weight: 700;
    font-size: var(--fs-mainheading);
    color: $HeadingColor;
    margin-bottom: 5vh;
  }
}

.form-container {
  .my-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    text-align: left;
    border: 3px solid $PrimaryColor;
    padding: 7vh;
    border-radius: 6px;

    input,
    textarea {
      margin: 20px;
      width: 80%;
      padding: 12px;
      font-size: var(--fs-paragraph);
      border-radius: 6px;
      background-color: $GhostColor;
      border: none;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 20%;
      background-color: $PrimaryColor;
      color: $white;
    }
  }
}

.address-container {
  display: flex;
  justify-content: center;

  flex-direction: column;
  text-align: left;
  background-image: linear-gradient(
    to bottom right,
    $PrimaryColor,
    $AccentColor
  );
  height: 100%;
  padding: 6vh;
  border-radius: 6px;
  h1 {
    color: $white;
    margin-bottom: 0vh;
    font-size: var(--fs-mainheading);
    margin-top: 4vh;
  }

  p {
    color: $white;
    padding: 1px;
    font-size: var(--fs-paragraph);
  }

  div {
    padding: 5vh;
    h2 {
      color: $white;
      font-size: 23px;

      font-weight: 600;
    }

    p {
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .contact-us-container {
    padding: 0px;
  }

  .form-container {
    .my-form {
      padding: 3vh 2vh;
      margin-bottom: 5px;

      input,
      textarea {
        margin: 20px;
        width: 90%;
      }
    }

    .button-container {
      input {
        width: 50%;
        padding: 6px 0px;
      }
    }
  }

  .address-container {
    margin-top: 5px;
    padding: 0vh 3vh;

    h1 {
      margin-bottom: 0vh;
      margin-top: 4vh;
    }

    p {
      padding: 1px;
    }

    div {
      padding: 3vh 2vh;
      h2 {
        font-size: 18px;
        font-weight: 700;
      }

      p {
        padding: 12px;
      }
    }
  }
}
