@import "../SCSS/config";

.intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  h1 {
    font-weight: 800;
    font-size: var(--fs-mainheading);
    color: $HeadingColor;
  }

  p {
    text-align: justify;
    color: $ParagraphColor;
    padding: 10px 5px;
    font-size: var(--fs-paragraph);
  }

  a {
    margin-top: 10px;
    color: $PrimaryColor;
    padding: 8px 18px 8px 18px;
    border: 1px solid $PrimaryColor;
    width: 20%;
    text-align: center;
    transition: 0.3s ease-in-out;
    border-radius: 6px;
    font-size: var(--fs-paragraph);
  }

  a:hover {
    background-color: $PrimaryColor;
    color: $white;
  }
}

.hero-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80%;
  }
}

.products-container {
  h1 {
    font-weight: 700;
    font-size: var(--fs-mainheading);
    color: $HeadingColor;
    margin-top: 15px;
  }

  p {
    color: $ParagraphColor;
    text-align: justify;
    padding: 10px 5px 30px 5px;
    font-size: var(--fs-paragraph);
  }
  .product-list {
    :nth-child(1) {
      background-color: $GhostColor;
    }

    :nth-child(4) {
      background-color: $GhostColor;
    }

    :nth-child(5) {
      background-color: $GhostColor;
    }

    :nth-child(8) {
      background-color: $GhostColor;
    }

    :nth-child(9) {
      background-color: $GhostColor;
    }

    :nth-child(12) {
      background-color: $GhostColor;
    }

    :nth-child(14) {
      background-color: $GhostColor;
    }

    :nth-child(15) {
      background-color: $GhostColor;
    }

    :nth-child(18) {
      background-color: $GhostColor;
    }
    :nth-child(19) {
      background-color: $GhostColor;
    }
    :nth-child(21) {
      background-color: $GhostColor;
    }

    .product-list-items {
      padding: 10vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      img {
        width: 40vh;
        height: 40vh;
        border-radius: 6px;
        background-color: transparent;
      }
      h3 {
        margin-top: 10px;
        font-weight: 600;
        background-color: transparent;
        font-size: var(--fs-heading);
      }
    }
  }
}

.services-container {
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: var(--fs-mainheading);
    color: $HeadingColor;
    margin-bottom: 3vh;
  }

  .service-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50vh;
    }
  }

  .service-txt-container {
    text-align: left;
    p {
      color: $ParagraphColor;
      text-align: justify;
      padding: 10px 5px 20px 5px;
      font-size: var(--fs-paragraph);
    }

    ul {
      li {
        background-color: $PrimaryColor;
        margin: 5px;
        padding: 5px;
        color: $white;
        font-weight: 500;
        font-size: var(--fs-paragraph);
      }
    }
  }
}

.why-us-container {
  h1 {
    font-weight: 700;
    font-size: var(--fs-mainheading);
    color: $HeadingColor;
  }

  p {
    color: $ParagraphColor;
    padding: 0px 5px 10px 5px;
    text-align: justify;
    font-size: var(--fs-paragraph);
  }

  ul {
    list-style: inside;
    padding: 20px;
    width: 80%;
    li {
      span {
        color: $SemiBlack;
      }
    }
  }

  .value-container {
    margin-top: 4vh;
  }
  .value-items {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    img {
      width: 10vh;
      margin: 0px 40px 40px 40px;
      filter: sepia(24%) saturate(118%) hue-rotate(187deg) brightness(93%)
        contrast(120%);
    }
  }

  .value-text-container {
    h2 {
      font-size: 20px;
      font-weight: 600;
      font-size: var(--fs-heading);
    }

    p {
      font-size: 15px;
    }
  }
}

.end-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vh;

  img {
    width: 40vh;
  }
}

.chanel-partner-container {
  h1 {
    font-weight: 700;
    font-size: var(--fs-mainheading);
    color: $HeadingColor;
    margin-top: 15px;
  }

  p {
    color: $ParagraphColor;
    text-align: justify;
    padding: 10px 5px 30px 5px;
    font-size: var(--fs-paragraph);
  }

  & > div {
    :first-child {
      display: flex;
      justify-content: center;
    }
    :last-child {
      padding: 20px 0px 0px 0px;
      img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        padding: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Home {
    padding: 4vh 5vh;
  }
  .intro-container {
    h1 {
      width: 100%;
    }

    a {
      margin-top: 10px;
      padding: 4px 0px 4px 0px;
      width: 30%;
    }
  }

  .hero-img-container {
    img {
      width: 70%;
    }
  }

  .products-container {
    padding: 0px 0px;
    h1 {
      font-weight: 700;
      font-size: var(--fs-mainheading);
      color: $HeadingColor;
      margin-top: 15px;
    }

    p {
      color: $ParagraphColor;
      padding: 10px 5px 30px 5px;
      font-size: var(--fs-paragraph);
    }
    .product-list {
      :nth-child(1) {
        background-color: $GhostColor;
      }

      :nth-child(3) {
        background-color: $GhostColor;
      }

      :nth-child(4) {
        background-color: transparent;
      }

      :nth-child(7) {
        background-color: $GhostColor;
      }

      :nth-child(8) {
        background-color: transparent;
      }

      .product-list-items {
        img {
          width: 25vh;
          height: 25vh;
        }
      }
    }
  }

  .services-container {
    .service-img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 35vh;
        border-radius: 6px;
      }
    }

    .service-txt-container {
      padding: 0px 0px;
      text-align: justify;
    }
  }

  .why-us-container {
    padding: 0px;

    .value-items {
      margin: 10px 0px;
      img {
        width: 8vh;
        margin-left: 0px;
        margin-right: 10px;
        margin-top: 0px;
      }
    }

    .value-text-container {
      p {
        font-size: var(--fs-paragraph);
        padding-top: 4px;
      }
    }
  }

  .end-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10vh;

    img {
      width: 25vh;
    }
  }
}
